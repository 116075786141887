const apiBaseURL = "https://trusty.cxchatgpt.ai/";
const urlPayments = `${apiBaseURL}api/gig/payroll/create`;

export const ApiConfig = {
  // redirect url
  redirectURI: `${apiBaseURL}`,

  // working days
  workingDays: `${urlPayments}/workingdays`,

  // Payments
  payments: `${urlPayments}/getMY`,

  // Payment history
  paymnentHistory: `${urlPayments}/History`,

  // Hr LOB
  // hrLOB: `${urlPayments}/lop`,
};
