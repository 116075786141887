import React, { useEffect, useContext } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import PaymentScreen from "./PaymentScreen";
import HistoryPaymentScreen from "./HistoryPaymentScreen";
import Result from "./Result";
import HistoryResult from "./HistoryResult";
import AccessDenied from "./AccessDenied";
// import AccessDeniedHR from "./AccessDeniedHR";
import ShowError from "./ShowError";
import { AuthContext } from "./AuthContext";
import { DataContext } from "./DataContext";
import Login from "./Login";
import WorkingDays from "./WorkingDays";
// import LOB from "./LOB";

export default function RoutesPlus() {
  // Using the useContext hook to get data from AuthContext and DataContext
  const { accounts, inProgress, login, instance } = useContext(AuthContext);
  const { resultData, historyResultData } = useContext(DataContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to login page if no accounts are present
    if (accounts.length === 0) {
      navigate("/");
    }
    if (!instance.getActiveAccount()) {
      navigate("/accessdenied");
    }
  }, [accounts, navigate, instance, historyResultData, resultData]);

  // Handle different loading stages
  if (inProgress === "login") {
    return <div>Logging in....</div>;
  }

  if (inProgress === "logout") {
    return <div>Logging out....</div>;
  }

  return (
    <Routes>
      {/* Set up routes based on user account status */}
      <Route
        path="/"
        element={
          accounts.length === 0 ? <Login login={login} /> : <WorkingDays />
        }
      />
      {accounts[0] && (
        <>
          <Route
            path="/historypaymentScreen"
            element={<HistoryPaymentScreen />}
          />
          <Route path="/paymentScreen" element={<PaymentScreen />} />
          <Route path="/workingDays" element={<WorkingDays />} />
          {/* <Route path="/lob" element={<LOB />} /> */}

          <Route
            path="/result"
            element={
              resultData ? <Result /> : <Navigate replace to="/paymentScreen" />
            }
          />

          <Route
            path="/historyresult"
            element={
              historyResultData ? (
                <HistoryResult />
              ) : (
                <Navigate replace to="/historypaymentScreen" />
              )
            }
          />

          <Route path="/accessdenied" element={<AccessDenied />} />
          {/* <Route path="/accessdenieduser" element={<AccessDeniedHR />} /> */}
          <Route path="/showerror" element={<ShowError />} />
        </>
      )}
      <Route path="*" element={<ShowError />} />
    </Routes>
  );
}
