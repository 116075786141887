import React, { useContext, useState, useEffect } from "react";
import logo from "../img/logo.png";
import { Link, useLocation } from "react-router-dom";
import { DataContext } from "./DataContext";
import { AuthContext } from "./AuthContext";
export default function Navbar() {
  // Using the useContext hook to get data from DataContext and AuthContext
  const { isLoading, resultData, historyResultData } = useContext(DataContext);
  const { logout, accounts } = useContext(AuthContext);

  const [activeLink, setActiveLink] = useState("");

  // Get current location
  const location = useLocation();

  // Update activeLink based on the current path
  useEffect(() => {
    const currentPath = location.pathname.substring(1); // Remove leading '/'
    setActiveLink(currentPath);
  }, [location]);

  const handleClick = (link) => {
    setActiveLink(link);
  };
  return (
    <>
      {/* Navigation bar with Bootstrap styling */}
      <nav
        className="navbar navbar-expand-lg"
        style={{ background: "#0D2B48" }}
      >
        {/* Brand logo */}
        <div className="container-fluid ">
          <Link className="navbar-brand">
            <img src={logo} alt="Bootstrap" width="90" height="30" />
          </Link>
          {/* Toggle button for mobile view */}
          <button
            className="navbar-toggler bg-white"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon text-white"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="navbar-nav me-auto"></div>
            {/* Navigation links */}
            <ul className="navbar-nav me-auto ">
              {!isLoading && (
                <li className="nav-item">
                  <Link
                    className={`navbar-brand nav-link text-white ${
                      activeLink === "workingDays" ? "active" : ""
                    } fs-6`}
                    to="/workingDays"
                    onClick={() => handleClick("workingDays")}
                  >
                    Working Days
                  </Link>
                </li>
              )}
              {!isLoading && (
                <li className="nav-item">
                  <Link
                    onClick={() => handleClick("paymentScreen")}
                    className={`navbar-brand nav-link text-white ${
                      activeLink === "paymentScreen" ? "active" : ""
                    } fs-6`}
                    to="/paymentScreen"
                  >
                    Payment Screen
                  </Link>
                </li>
              )}
              {!isLoading && resultData && (
                <li className="nav-item">
                  <Link
                    onClick={() => handleClick("result")}
                    className={`navbar-brand nav-link text-white ${
                      activeLink === "result" ? "active" : ""
                    } fs-6`}
                    to="/result"
                  >
                    Payments Result
                  </Link>
                </li>
              )}
              {!isLoading && (
                <li className="nav-item">
                  <Link
                    onClick={() => handleClick("historypaymentScreen")}
                    className={`navbar-brand nav-link text-white ${
                      activeLink === "historypaymentScreen" ? "active" : ""
                    } fs-6`}
                    to="/historypaymentScreen"
                  >
                    History Payment Screen
                  </Link>
                </li>
              )}
              {!isLoading && historyResultData && (
                <li className="nav-item">
                  <Link
                    onClick={() => handleClick("historyresult")}
                    className={`navbar-brand nav-link text-white ${
                      activeLink === "historyresult" ? "active" : ""
                    } fs-6`}
                    to="/historyresult"
                  >
                    History Result
                  </Link>
                </li>
              )}
              {/* {!isLoading && (
                <li className="nav-item">
                  <Link
                    onClick={() => handleClick("lob")}
                    className={`navbar-brand nav-link text-white ${
                      activeLink === "lob" ? "active" : ""
                    } fs-6`}
                    to="/lob"
                  >
                    Calculate LOP
                  </Link>
                </li>
              )} */}
            </ul>
            {/* User account-related links */}
            <ul className="navbar-nav d-flex">
              {accounts[0] <= 0 && (
                <li className="nav-item">
                  <Link
                    className="navbar-brand nav-link text-white fs-6"
                    to="/login"
                  >
                    Login
                  </Link>
                </li>
              )}
              {accounts[0] && (
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link navbar-brand dropdown-toggle text-white fs-6"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {accounts[0].username}
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item fs-6" onClick={logout}>
                        Logout
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
