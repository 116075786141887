import React, { useState, useContext, useEffect } from "react";
import Navbar from "./Navbar";
import axios from "axios";
import { DataContext } from "./DataContext";
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import { ApiConfig } from "../Config/ApiConfig";
function WorkingDays() {
  // Fetch data and functions from DataContext and AuthContext
  const { setIsLoading, isLoading } = useContext(DataContext);
  const { instance, getToken } = useContext(AuthContext);
  // State variables to manage input values and timer
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [timer, setTimer] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    let interval;
    if (isLoading) {
      // Increment timer every second when loading
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }
    return () => {
      // Clean up interval when component unmounts
      clearInterval(interval);
    };
  }, [isLoading]);

  // Function to format time in HH:mm:ss format
  const formatTime = (time) => {
    const hours = Math.floor(time / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((time % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (time % 60).toString().padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const confirmSubmit = window.confirm("Are you sure you want to submit?");
    if (confirmSubmit) {
      try {
        setIsLoading(true);
        const token = await getToken();
        const response = await axios.post(
          ApiConfig.workingDays,
          {
            email: instance.getActiveAccount().username,
            month: month,
            year: year,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data === 1) alert("Success");
        else alert(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/accessdenieduser");
          return; // Prevent further execution after navigating due to a 401 error
        }
        navigate("/showerror");
        return;
      } finally {
        // Reset timer, month, year, and loading state
        setTimer(0);
        setMonth("");
        setYear("");
        setIsLoading(false);
      }
    }
  };

  //Handeling last 3 months and year
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const lastThreeMonths = [];
  for (let i = 0; i < 3; i++) {
    const monthIndex = (currentMonth - i + 12) % 12;
    const year = currentYear - Math.floor((currentMonth - i) / 12);
    const monthValue = String(monthIndex + 1).padStart(2, "0");
    lastThreeMonths.push({
      value: monthValue,
      label: new Date(year, monthIndex).toLocaleString("default", {
        month: "long",
      }),
    });
  }

  const startYear = currentMonth <= 1 ? currentYear - 1 : currentYear;
  const endYear = currentYear;
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, index) => startYear + index
  );

  return (
    <>
      <Navbar />
      <div className="container mt-3">
        <form onSubmit={handleSubmit}>
          <h2 className="text-center">Working Days</h2>
          {isLoading ? (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <div>{formatTime(timer)}</div>
            </div>
          ) : (
            <div>
              <div className="row m-4">
                <div className="col-12 text-center m-3">
                  Please select values to be processed
                </div>
                <div className="col-6">
                  <select
                    className="form-select"
                    name="month"
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                    required
                  >
                    <option value="">Month</option>
                    {lastThreeMonths.map((month) => (
                      <option key={month.value} value={month.value}>
                        {month.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-6">
                  <select
                    className="form-select col-3"
                    name="year"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                    required
                  >
                    <option value="">Year</option>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
          <div className="text-center mt-4">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default WorkingDays;
