import React from "react";
import Routes from "./components/Routes";
import "./index.css";

export default function App() {
  return (
    <div>
      <Routes />
    </div>
  );
}
