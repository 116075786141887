import React, { createContext, useState } from "react";
// Create a context to hold data-related state and functions
export const DataContext = createContext();

// DataProvider component encapsulates data-related state and logic
export const DataProvider = ({ children }) => {
  // State variables to manage different pieces of data
  const [historyResultData, setHistoryResultData] = useState(null); // Historical result data
  const [historyDate, setHistoryDate] = useState(null); // Date for historical data
  const [resultData, setResultData] = useState(null); // Current result data
  const [date, setDate] = useState(null); // Current date
  const [paymentsAPIResponse, setPaymentsAPIRepsonse] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Loading indicator
  const api = "https://20.219.23.42:8000";
  // const api = "http://172.16.1.105:8000";

  return (
    // Provide data state and functions to the context
    <DataContext.Provider
      value={{
        resultData,
        setResultData,
        historyResultData,
        setHistoryResultData,
        date,
        setDate,
        historyDate,
        setHistoryDate,
        paymentsAPIResponse,
        setPaymentsAPIRepsonse,
        isLoading,
        setIsLoading,
        api,
      }}
    >
      {children} {/* Render the child components */}
    </DataContext.Provider>
  );
};
