/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import { DataContext } from "./DataContext";
import Navbar from "./Navbar";
import { CSVLink } from "react-csv";

const DataTable = () => {
  // Access data-related state from DataContext
  const { resultData, date } = useContext(DataContext);

  // Extract result data into separate variables
  const [a, b, c, d, e] = resultData;

  // Extract month and year from date
  const [month, year] = date;

  // State variables to manage data and filters
  const [dataA, setDataA] = useState(a);
  const [dataB, setDataB] = useState(b);
  const [dataC, setDataC] = useState(c);
  const [dataD, setDataD] = useState(d);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfigA, setSortConfigA] = useState({
    key: null,
    direction: null,
  });
  const [sortConfigB, setSortConfigB] = useState({
    key: null,
    direction: null,
  });
  const [sortConfigD, setSortConfigD] = useState({
    key: null,
    direction: null,
  });
  const [currencyFilter, setCurrencyFilter] = useState("");
  const [lobFilter, setLobFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [runNumberFilter, setRunNumberFilter] = useState("");

  // Code for generating run numbers and lobs
  console.log(a);
  const runNumbers = [...new Set(a.map((item) => item.Run_No))];
  const lobs = [...new Set(a.map((item) => item.Payment_Plan))];
  const status = [...new Set(a.map((item) => item.Payout_Status))];
  const highestRunNumber = Math.max(...runNumbers);
  const previousRunNumbers = runNumbers
    .filter((runNumber) => runNumber !== highestRunNumber)
    .sort((a, b) => b - a)
    .slice(0, 2);

  // Effect to filter and update data based on the highest run number
  useEffect(() => {
    // Filter data for each category based on the highest run number
    const filteredDataA = a.filter((item) => item.Run_No === highestRunNumber);
    setDataA(filteredDataA);
    const filteredDataB = b.filter((item) => item.Run_No === highestRunNumber);
    setDataB(filteredDataB);
    const filteredDataD = d.filter((item) => item.Run_No === highestRunNumber);
    setDataD(filteredDataD);
  }, [a, b, d, highestRunNumber]);

  const mapCurrency = (currency) => {
    if (currency === 1) {
      return "INR"; // If currency code is 1, return "INR"
    } else if (currency === 2) {
      return "USD"; // If currency code is 2, return "USD"
    }
    return ""; // For any other currency code, return an empty string
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value); // Update searchTerm with the value entered by the user
  };

  const handleColumnSortA = (key) => {
    let direction = "asc";

    // If the current sorting configuration matches the key and is already in ascending order, switch to descending order
    if (
      sortConfigA &&
      sortConfigA.key === key &&
      sortConfigA.direction === "asc"
    ) {
      direction = "desc";
    }
    // Create a sorted copy of the data using the specified key and direction
    const sortedData = [...dataA].sort((a, b) => {
      if (key === "Run_No" || key === "Net_Payable") {
        return direction === "asc" ? a[key] - b[key] : b[key] - a[key];
      } else if (key === "Currency") {
        const currencyA = mapCurrency(a[key]);
        const currencyB = mapCurrency(b[key]);
        return direction === "asc"
          ? currencyA.localeCompare(currencyB)
          : currencyB.localeCompare(currencyA);
      } else {
        if (a[key] < b[key]) {
          return direction === "asc" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === "asc" ? 1 : -1;
        }
        return 0;
      }
    });
    // Update the sorting configuration and data state
    setSortConfigA({ key, direction });
    setDataA(sortedData);
  };
  const handleColumnSortB = (key) => {
    let direction = "asc";

    // If the current sorting configuration matches the key and is already in ascending order, switch to descending order
    if (
      sortConfigB &&
      sortConfigB.key === key &&
      sortConfigB.direction === "asc"
    ) {
      direction = "desc";
    }

    // Create a sorted copy of the data using the specified key and direction
    const sortedData = [...dataB].sort((a, b) => {
      if (
        key === "Run_No" ||
        key === "Failure" ||
        key === "Success" ||
        key === "Total"
      ) {
        return direction === "asc" ? a[key] - b[key] : b[key] - a[key];
      } else {
        if (a[key] < b[key]) {
          return direction === "asc" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === "asc" ? 1 : -1;
        }
        return 0;
      }
    });
    // Update the sorting configuration and data state
    setSortConfigB({ key, direction });
    setDataB(sortedData);
  };
  const handleColumnSortD = (key) => {
    let direction = "asc";

    // If the current sorting configuration matches the key and is already in ascending order, switch to descending order
    if (
      sortConfigD &&
      sortConfigD.key === key &&
      sortConfigD.direction === "asc"
    ) {
      direction = "desc";
    }
    // Create a sorted copy of the data using the specified key and direction
    const sortedData = [...dataD].sort((a, b) => {
      if (
        key === "Run_No" ||
        key === "Failure" ||
        key === "Success" ||
        key === "ID_grouped"
      ) {
        return direction === "asc" ? a[key] - b[key] : b[key] - a[key];
      } else {
        if (a[key] < b[key]) {
          return direction === "asc" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === "asc" ? 1 : -1;
        }
        return 0;
      }
    });

    // Update the sorting configuration and data state
    setSortConfigD({ key, direction });
    setDataD(sortedData);
  };
  // Function to handle changes in the currency filter dropdown
  const handleCurrencyFilterChange = (e) => {
    // Update the currency filter state with the selected value
    setCurrencyFilter(e.target.value);
  };

  // Function to handle changes in the Line of Business (LOB) filter dropdown
  const handleLobFilterChange = (e) => {
    // Update the LOB filter state with the selected value
    setLobFilter(e.target.value);
  };

  // Function to handle changes in the status filter dropdown
  const handleStatusFilterChange = (e) => {
    // Update the status filter state with the selected value
    setStatusFilter(e.target.value);
  };

  // Function to handle changes in the Run Number filter dropdown
  const handleRunNumberFilterChange = (e) => {
    // Get the selected run number from the dropdown
    const selectedRunNumber = e.target.value;
    // Update the Run Number filter state with the selected value
    setRunNumberFilter(selectedRunNumber);

    // If "Latest Run" is selected in the Run Number filter dropdown
    if (selectedRunNumber === "") {
      // Calculate the highest run number in the data set 'a'
      const filteredData = a.filter((item) => {
        // Calculate the highest run number in the data set 'a'
        const runNumber = Math.max(...a.map((item) => item.Run_No));
        return item.Run_No === runNumber;
      });
      // Update the state 'dataA' with the filtered data
      setDataA(filteredData);
    } else {
      // If a specific run number is selected in the dropdown

      // Filter the data set 'a' to include only items with the selected run number
      const filteredData = a.filter((item) => {
        return item.Run_No.toString() === selectedRunNumber;
      });
      // Update the state 'dataA' with the filtered data
      setDataA(filteredData);
    }

    // If "Latest Run" is selected in the Run Number filter dropdown
    if (selectedRunNumber === "") {
      // Calculate the highest run number in the data set 'b'
      const filteredDataB = b.filter((item) => {
        // Filter the data set 'b' to include only items with the highest run number
        const runNumber = Math.max(...b.map((item) => item.Run_No));
        return item.Run_No === runNumber;
      });
      // Update the state 'dataB' with the filtered data
      setDataB(filteredDataB);
    } else {
      // If a specific run number is selected in the dropdown

      // Filter the data set 'b' to include only items with the selected run number
      const filteredData = b.filter((item) => {
        return item.Run_No.toString() === selectedRunNumber;
      });
      // Update the state 'dataA' with the filtered data
      setDataB(filteredData);
    }
    // If "Latest Run" is selected in the Run Number filter dropdown
    if (selectedRunNumber === "") {
      // Calculate the highest run number in the data set 'd'
      const filteredDataD = d.filter((item) => {
        // Filter the data set 'd' to include only items with the highest run number
        const runNumber = Math.max(...d.map((item) => item.Run_No));
        return item.Run_No === runNumber;
      });
      // Update the state 'dataD' with the filtered data
      setDataD(filteredDataD);
    } else {
      // If a specific run number is selected in the dropdown

      // Filter the data set 'd' to include only items with the selected run number
      const filteredData = d.filter((item) => {
        return item.Run_No.toString() === selectedRunNumber;
      });
      // Update the state 'dataD' with the filtered data
      setDataD(filteredData);
    }
  };

  // Filter the data set 'dataA' based on various search and filter criteria
  const filteredDataA = dataA.filter((item) => {
    // Check if the 'Name' field includes the search term (case-insensitive)
    const nameMatch = item.Name.toLowerCase().includes(
      searchTerm.toLowerCase()
    );
    // Check if the 'MId' field includes the search term (case-insensitive)
    const midMatch = item.MId.toLowerCase().includes(searchTerm.toLowerCase());
    // Check if the 'cr8b3_gw_email' field includes the search term (case-insensitive)
    const idMatch = item.cr8b3_gw_email
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    // Check if the currency matches the selected currency filter or if no filter is applied
    const currencyMatch =
      !currencyFilter || item.Currency.toString() === currencyFilter;
    // Check if the payment plan matches the selected LOB filter or if no filter is applied
    const lobMatch = !lobFilter || item.Payment_Plan === lobFilter;
    // Check if the payout status matches the selected status filter or if no filter is applied
    const statusMatch =
      !statusFilter || item.Payout_Status.toLowerCase() === statusFilter;
    // Return 'true' if all conditions are met, indicating that the item should be included in the filtered data
    return (
      (nameMatch || midMatch || idMatch) &&
      currencyMatch &&
      lobMatch &&
      statusMatch
    );
  });

  // Function to infer the headers of the 'a' data set
  const inferHeadersA = () => {
    // Check if the 'a' data set is empty, return an empty array if so
    if (a.length === 0) {
      return [];
    }
    // Get the first object in the 'a' data set
    const firstObject = a[0];

    // Extract the keys (headers) of the first object
    const headers = Object.keys(firstObject);

    // Return the list of headers
    return headers;
  };
  // List of headers to hide in the 'headersA' array
  const headerToHideA = ["cr8b3_gw_email", "ID_merged"];

  // Filter the inferred headers to exclude the headers in 'headerToHideA'
  const headersA = inferHeadersA().filter(
    (header) => !headerToHideA.includes(header)
  );

  // Function to infer the headers of the 'b' data set
  const inferHeadersB = () => {
    // Check if the 'b' data set is empty, return an empty array if so
    if (b.length === 0) {
      return [];
    }

    // Get the first object in the 'b' data set
    const firstObject = b[0];

    // Extract the keys (headers) of the first object
    const headers = Object.keys(firstObject);

    // Return the list of headers
    return headers;
  };

  // Header that needs to be hidden in the 'headersB' array
  const headerToHideB = "ID_grouped";

  // Filter the inferred headers to exclude the 'headerToHideB' header
  const headersB = inferHeadersB().filter((header) => header !== headerToHideB);

  // Function to infer the headers of the 'c' data set
  const inferHeadersC = () => {
    // Check if the 'c' data set is empty, return an empty array if so
    if (c.length === 0) {
      return [];
    }

    // Get the first object in the 'c' data set
    const firstObject = c[0];

    // Extract the keys (headers) of the first object
    const headers = Object.keys(firstObject);

    // Return the list of headers
    return headers;
  };

  // Create an array of CSV headers for 'c' data set using the inferred headers
  const csvHeadersC = inferHeadersC().map((header) => ({
    label: header,
    key: header,
  }));

  // Create an array of CSV data for 'c' data set
  const csvDataC = dataC.map((item) => {
    // Initialize an empty object to store CSV data for each item
    const csvItem = {};

    // Iterate through each header and populate the CSV item object
    inferHeadersC().forEach((header) => {
      csvItem[header] = item[header]; // Assign value from 'item' to the corresponding header
    });

    // Return the populated CSV item object
    return csvItem;
  });

  // Function to infer headers for the 'd' data set
  const inferHeadersD = () => {
    if (d.length === 0) {
      return []; // If 'd' data set is empty, return an empty array
    }
    const firstObject = d[0]; // Take the first object in the 'd' data set
    const headers = Object.keys(firstObject); // Extract the keys (headers) from the first object
    return headers; // Return the array of inferred headers
  };

  // Array of headers to hide for 'd' data set
  const headerToHideD = ["ID_pivot"];

  // Array of headers for 'd' data set after filtering out the headers to hide
  const headersD = inferHeadersD().filter(
    (header) => !headerToHideD.includes(header)
  );

  // Function to get the month name based on the month number
  function getMonthName(monthNumber) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return months[monthNumber - 1] || ""; // Return the corresponding month name from the 'months' array
  }

  // Call the 'getMonthName' function to get the month name for the given 'month' value
  const monthName = getMonthName(month);

  // Function to calculate the total net payable from the filtered data
  const calculateTotalNetPayable = () => {
    // Map the 'Net_Payable' property from each item in 'filteredDataA'
    const filteredNetPayables = filteredDataA.map((item) => item.Net_Payable);

    // Use the 'reduce' function to sum up all the net payables
    return filteredNetPayables.reduce(
      (total, netPayable) => total + netPayable,
      0
    );
  };

  // Get the count of items in the 'filteredDataA' array
  const filteredDataCount = filteredDataA.length;

  const now = new Date();

  // Format the timestamp as "YYYY-MM-DD_HH-mm-ss" (you can adjust the format as needed)
  const timestamp = `${now.getFullYear()}${(now.getMonth() + 1)
    .toString()
    .padStart(2, "0")}${now.getDate().toString().padStart(2, "0")}`;

  // Function to trigger download of Excel file from a Base64 string

  const downloadExcelFile = () => {
    const fileName = `Bank_File_${timestamp}_${highestRunNumber}.xlsx`;
    // Decode Base64 to binary
    const binaryString = window.atob(e);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);

    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob object from the binary array
    const blob = new Blob([bytes.buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create an anchor element and trigger download
    const a = document.createElement("a");
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();

    // Release resources
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <Navbar />
      <div className="my-2 mx-5">
        <div className="filter-section mb-3">
          <div className="row mb-3">
            <CSVLink
              data={csvDataC}
              headers={csvHeadersC}
              filename={`GW_PaymentDetails_${timestamp}_${highestRunNumber}.csv`}
              className="btn btn-outline-secondary my-2 col-3 mx-auto"
              target="_blank"
            >
              Download GW Payment Details
            </CSVLink>
            <button
              className="btn btn-outline-secondary my-2 col-3 mx-auto"
              onClick={downloadExcelFile}
            >
              Download Bank File
            </button>
            <p className="h5 my-auto text-center text-capitalize col-4">
              {monthName} {year}
            </p>
            <div className="col-4">
              <input
                type="text"
                className="form-control"
                placeholder="Search by Name or MID"
                value={searchTerm}
                onChange={handleSearchInputChange}
              />
            </div>
            <div className="col">
              <select
                className="form-select"
                value={currencyFilter}
                onChange={handleCurrencyFilterChange}
              >
                <option value="">All Currencies</option>
                <option value={1}>INR</option>
                <option value={2}>USD</option>
              </select>
            </div>
            <div className="col">
              <select
                className="form-select"
                value={lobFilter}
                onChange={handleLobFilterChange}
              >
                <option value="">All LOBs</option>
                {lobs.map((lob) => (
                  <option key={lob} value={lob}>
                    {lob}
                  </option>
                ))}
              </select>
            </div>
            <div className="col">
              <select
                className="form-select"
                value={statusFilter}
                onChange={handleStatusFilterChange}
              >
                <option value="">All Statuses</option>
                {status.map((status) => (
                  <option
                    key={status.toLowerCase()}
                    value={status.toLowerCase()}
                  >
                    {status}
                  </option>
                ))}
              </select>
            </div>
            <div className="col">
              <select
                className="form-select"
                value={runNumberFilter}
                onChange={handleRunNumberFilterChange}
              >
                <option value="">Latest Run</option>
                {previousRunNumbers.map((runNumber) => (
                  <option key={runNumber} value={runNumber}>
                    {runNumber}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div
          className="table-container my-3"
          style={{ maxHeight: "340px", overflow: "auto" }}
        >
          <table className="table table-striped">
            <thead>
              <tr>
                {headersA.map((header) => (
                  <th
                    onClick={() => handleColumnSortA(`${header}`)}
                    key={header}
                    style={{
                      position: "sticky",
                      top: "0",
                      backgroundColor: " #fff",
                      zIndex: "1",
                    }}
                  >
                    {header}{" "}
                    {sortConfigA.key === `${header}` && (
                      <span>{sortConfigA.direction === "asc" ? "▲" : "▼"}</span>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredDataA.map((item) => (
                <tr key={item.ID_merged}>
                  {headersA.map((header) =>
                    header === "Currency" ? (
                      <td key={header}>{mapCurrency(item.Currency)}</td>
                    ) : (
                      <td key={header}>{item[header]}</td>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <hr></hr>
        <p className="h5">
          Records Count: {filteredDataCount} | Total Net Payable:{" "}
          {calculateTotalNetPayable().toFixed(2)}
        </p>
        <hr></hr>
        <h3 className="text-center">Count of Gigworkers</h3>
        <table className="table table-striped">
          <thead>
            <tr>
              {headersB.map((header) => (
                <th onClick={() => handleColumnSortB(`${header}`)} key={header}>
                  {header}{" "}
                  {sortConfigB.key === `${header}` && (
                    <span>{sortConfigB.direction === "asc" ? "▲" : "▼"}</span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataB.map((item) => (
              <tr key={item.ID_grouped}>
                {headersB.map((header) => (
                  <td key={header}>{item[header]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <hr></hr>
        <h3 className="text-center">Net Payable of Gigworkers</h3>
        <table className="table table-striped">
          <thead>
            <tr>
              {headersD.map((header) => (
                <th onClick={() => handleColumnSortD(`${header}`)} key={header}>
                  {header}{" "}
                  {sortConfigD.key === `${header}` && (
                    <span>{sortConfigD.direction === "asc" ? "▲" : "▼"}</span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataD.map((item) => (
              <tr key={item.ID_pivot}>
                {headersD.map((header) => (
                  <td key={header}>{item[header]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DataTable;
