import React from "react";
import Navbar from "./Navbar";
import "./ShowError.css";

const ShowError = () => {
  return (
    // Display a custom error page with a 404 message and styling
    <div className="error-page">
      <Navbar />
      <div className="error-container mx-auto flex-grow">
        <h1 className="error_heading_1">:(</h1>
        <br />
        <h2 className="error_heading_2 mx-4">
          A <span>404</span> error occurred, Page not found, check the URL and
          try again.
        </h2>
        <br />
        <br />
      </div>
    </div>
  );
};

export default ShowError;
