import React from "react";
import logo from "../img/logo.png";
import { ApiConfig } from "../Config/ApiConfig";
function Login({ login }) {
  console.log(`serving : ${ApiConfig.redirectURI}`);
  return (
    <section
      className="h-100 gradient-form"
      style={{ backgroundColor: "#eee" }}
    >
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-xl-10">
            <div className="card rounded-3 text-black">
              <div className="row g-0">
                <div className="col-lg-6">
                  <div className="card-body p-md-5 mx-md-4">
                    <div className="text-center mt-3">
                      <img src={logo} alt="logo" style={{ width: "185px" }} />
                    </div>
                    <div className="container">
                      <div className="text-center m-5">
                        <h3 className="text-secondary m-3">
                          Welcome to Payments
                        </h3>
                        <button
                          className="btn btn-outline-primary btn-lg px-5 py-3"
                          onClick={login}
                        >
                          Login with Microsoft
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 d-flex align-items-center gradient-custom-2">
                  <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                    <h4 className="mb-4">We are more than just a company</h4>
                    <p className="small mb-0">
                      Welcome to Gigmos, a trusted place to provide services,
                      solutions, and staffing market space. Gigmos is making
                      customer support more effective with the use of the Gig
                      economy and AI/cognitive computing.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
