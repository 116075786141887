import React, { useState, useContext, useEffect } from "react";
import Navbar from "./Navbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DataContext } from "./DataContext";
import { AuthContext } from "./AuthContext";
import { ApiConfig } from "../Config/ApiConfig";

function PaymentScreen() {
  // Fetch data and functions from DataContext and AuthContext
  const {
    setResultData,
    setDate,
    setPaymentsAPIRepsonse,
    setIsLoading,
    isLoading,
  } = useContext(DataContext);
  const { instance, getToken } = useContext(AuthContext);
  // State variables to manage input values and timer
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [timer, setTimer] = useState(0);

  // Effect to update the timer when loading
  useEffect(() => {
    let interval;
    if (isLoading) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isLoading]);

  // Function to format time as HH:MM:SS
  const formatTime = (time) => {
    const hours = Math.floor(time / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((time % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (time % 60).toString().padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  };

  const navigate = useNavigate();

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Confirm the submission with a dialog
    const confirmSubmit = window.confirm("Are you sure you want to submit?");
    if (confirmSubmit) {
      try {
        // Set loading state
        setIsLoading(true);

        // Reset history result data and set selected history date
        setResultData(null);
        setDate([month, year]);

        // Get the authentication token
        const token = await getToken();
        // Make a POST request to fetch data
        const response = await axios.post(
          ApiConfig.payments,
          {
            email: instance.getActiveAccount().username,
            month: month,
            year: year,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setPaymentsAPIRepsonse(response);
        // Check if the response data length is not as expected
        if (response.data.length !== 5) {
          // Show alert with the response data and navigate to error page
          alert(response.data[0]);
          return;
        }
        // Set result data and navigate to history result page
        setResultData(response.data);
        navigate("/result");
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/accessdenieduser");
          return;
        }
        navigate("/showerror");
        return;
      } finally {
        // Reset timer, month, year, and loading state
        setTimer(0);
        setMonth("");
        setYear("");
        setIsLoading(false);
      }
    }
  };

  // Get the current date
  const currentDate = new Date();

  // Get the current month (0-indexed)
  const currentMonth = currentDate.getMonth();

  // Get the current year
  const currentYear = currentDate.getFullYear();

  // Code to generate last three months and years

  // Array to store the last three months' options
  const lastThreeMonths = [];

  // Loop to generate options for the last three months
  for (let i = 0; i < 3; i++) {
    // Calculate the index of the month and the corresponding year
    const monthIndex = (currentMonth - i + 12) % 12;
    const year = currentYear - Math.floor((currentMonth - i) / 12);

    // Convert month index to padded string and get the month label
    const monthValue = String(monthIndex + 1).padStart(2, "0");

    // Add month and label to the lastThreeMonths array
    lastThreeMonths.push({
      value: monthValue,
      label: new Date(year, monthIndex).toLocaleString("default", {
        month: "long",
      }),
    });
  }

  // Calculate the starting and ending years for the year dropdown
  const startYear = currentMonth <= 1 ? currentYear - 1 : currentYear;
  const endYear = currentYear;

  // Generate an array of years based on the start and end years
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, index) => startYear + index
  );

  return (
    <>
      <Navbar />
      <div className="container">
        <form onSubmit={handleSubmit}>
          {/* Form content */}
          <h2 className="text-center">Welcome to Gateway Payment Processing</h2>
          {isLoading ? (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <div>{formatTime(timer)}</div>
            </div>
          ) : (
            <div>
              <div className="row m-4">
                <div className="col-12 text-center m-3">
                  Please select values to be processed
                </div>
                <div className="col-6">
                  <select
                    className="form-select"
                    name="month"
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                    required
                  >
                    <option value="">Month</option>
                    {lastThreeMonths.map((month) => (
                      <option key={month.value} value={month.value}>
                        {month.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-6">
                  <select
                    className="form-select col-3"
                    name="year"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                    required
                  >
                    <option value="">Year</option>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <table className="table mt-5">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Pre-Conditions</th>
                    <th scope="col">Checklist</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>
                      <label
                        className="form-check-label"
                        htmlFor="attendanceLeaves"
                      >
                        Attendance/Leaves
                      </label>
                    </td>
                    <td>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="attendanceLeaves"
                        id="attendanceLeaves"
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>
                      <label className="form-check-label" htmlFor="exceptions">
                        Exceptions
                      </label>
                    </td>
                    <td>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="exceptions"
                        id="exceptions"
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>
                      <label className="form-check-label" htmlFor="arrears">
                        Arrears
                      </label>
                    </td>
                    <td>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="arrears"
                        id="arrears"
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">4</th>
                    <td>
                      <label className="form-check-label" htmlFor="deducation">
                        Deduction
                      </label>
                    </td>
                    <td>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="deduction"
                        id="deduction"
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">5</th>
                    <td>
                      <label className="form-check-label" htmlFor="paymentPlan">
                        Payment Plan
                      </label>
                    </td>
                    <td>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="paymentPlan"
                        id="paymentPlan"
                        required
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          <div className="text-center mt-4">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default PaymentScreen;
