import React, { useContext } from "react";
import "./AccessDenied.css";
import { AuthContext } from "./AuthContext";
// This component represents the page shown when access is denied to a user.
export default function AccessDenied() {
  // Access the logout function from the AuthContext using the useContext hook.
  const { logout } = useContext(AuthContext);
  return (
    <div className="access-denied-wrapper">
      <div className="lock"></div>
      <div className="message text-center">
        <h1>Access to this page is restricted</h1>
        <p>
          Please check with the site admin if you believe this is a mistake.
        </p>
        {/* Button to allow the user to logout */}
        <button className="btn btn-outline-danger" onClick={logout}>
          Logout
        </button>
      </div>
    </div>
  );
}
