import React, { createContext, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
// Create a context to hold authentication-related functions and auth data
const AuthContext = createContext();
// Configuration for the initial login request
const loginRequest = {
  // scopes: ["https://staging.crm.dynamics.com/.default"],
  scopes: ["User.ReadBasic.All"], // Scopes define the permissions you need from the user
};

// AuthProvider component encapsulates authentication logic
const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const { instance, accounts, inProgress } = useMsal();

  useEffect(() => {
    // Redirect to the homepage if no accounts are found
    if (accounts.length <= 0) {
      navigate("/");
    }
  }, [accounts, navigate, instance]);

  // Function to initiate the login process
  // const login = async () => {
  //   try {
  //     await instance.loginPopup(loginRequest).then((response) => {
  //       console.log(response);
  //       if (
  //         response.idTokenClaims &&
  //         response.idTokenClaims.groups &&
  //         (response.idTokenClaims.groups.includes(
  //           "683a4c1d-cb77-42e6-84c3-da8ef8cb2169"
  //         ) ||
  //           response.idTokenClaims.groups.includes(
  //             "9727af0e-a140-4aba-8047-1bc7a128c7c8"
  //           ))
  //       ) {
  //         navigate("/accessdenied"); // Redirect to access denied page
  //       } else instance.setActiveAccount(response.account); // Set the active account
  //     });
  //   } catch (error) {
  //     console.log("Error during login:", error);
  //   }
  // };
  const login = async () => {
    try {
      await instance.loginPopup(loginRequest).then((response) => {
        if (
          response.idTokenClaims &&
          response.idTokenClaims.groups &&
          (response.idTokenClaims.groups.includes(
            "9727af0e-a140-4aba-8047-1bc7a128c7c8"
          ) ||
            response.idTokenClaims.groups.includes(
              "34f8c277-a4c5-44be-b08a-a94c1a06470e"
            ))
        ) {
          instance.setActiveAccount(response.account);
        } else {
          navigate("/accessdenied");
        }
      });
    } catch (error) {
      console.log("Error during login:", error);
    }
  };

  // Function to retrieve an access token
  const getToken = async () => {
    try {
      const account = instance.getActiveAccount();
      if (!account) {
        alert("No active account found");
      }
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account,
      });

      return response.accessToken;
    } catch (error) {
      alert("Error acquiring token silently:", error);
      throw error;
    }
  };
  // Function to initiate the logout process
  const logout = () => {
    instance.logoutPopup();
  };

  return (
    // Provide authentication functions and data to the context
    <AuthContext.Provider
      value={{
        logout,
        login,
        getToken,
        instance,
        inProgress,
        accounts,
      }}
    >
      {children} {/* Render the child components */}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
