import React from "react";
import ReactDOM from "react-dom/client";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { BrowserRouter as Router } from "react-router-dom";
import { DataProvider } from "./components/DataContext";
import { AuthProvider } from "./components/AuthContext";
import { ApiConfig } from "./Config/ApiConfig";
import App from "./App";
import "./index.css";

const config = {
  auth: {
    clientId: "13122304-88a1-4e68-bc6b-3cd2cede5565", // Replace with your Application (client) ID
    authority:
      "https://login.microsoftonline.com/1bde36b2-2acb-484b-8e91-7b53bb308488",
    redirectUri: ApiConfig.redirectURI,
    // redirectUri: `http://localhost:3000/`,
  },
  // cache: {
  //   cacheLocation: 'localStorage', // or 'localStorage'
  //   storeAuthStateInCookie: true
  // }
};
const pca = new PublicClientApplication(config);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <Router>
        <AuthProvider>
          <DataProvider>
            <App />
          </DataProvider>
        </AuthProvider>
      </Router>
    </MsalProvider>
  </React.StrictMode>
);
